import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { PinCode } from "baseui/pin-code";
import { SIZE } from "baseui/input";
import { Button, SHAPE } from "baseui/button";
import { useStyletron } from "baseui";
import { toaster, ToasterContainer } from "baseui/toast";
import { Notification, KIND as NotiKIND } from "baseui/notification";
import { Auth } from "@aws-amplify/auth";
import AppContext from "../context/AppContext";
import { Trans, useTranslation } from "react-i18next";

const ConfirmEmailCode = ({ email, password }) => {
  const [confirmationCodeValues, setConfirmationCodeValues] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [css] = useStyletron();
  const ctx = useContext(AppContext);
  const history = useHistory();
  const { t } = useTranslation("outside");

  const confirmationContainer = css({
    margin: "0.8rem 0",
  });

  const inputContainer = css({
    margin: "0.8rem 0",
  });

  const resendCode = async (e) => {
    e.preventDefault();
    try {
      await Auth.resendSignUp(email);
      return toaster.positive(
        <>
          {t("confirmCode.codeSent")} {email}
        </>
      );
    } catch (e) {
      return toaster.negative(<>{e.message}</>);
    }
  };

  const handleRegisterConfirmation = async (e) => {
    e.preventDefault();

    const confirmationCode = confirmationCodeValues.join("");
    if (!confirmationCode) return toaster.negative(<>{t("error.codeEmpty")}</>);
    setIsLoading(true);

    try {
      await Auth.confirmSignUp(email, confirmationCode);
      await Auth.signIn(email, password);
      ctx.setIsAuth(true);
      history.push("/");
    } catch (e) {
      setIsLoading(false);
      if (e.code === "AliasExistsException") {
        return toaster.negative(<>{t("error.aliasExists")}</>);
      } else if (e.code === "CodeMismatchException") {
        return toaster.negative(<>{t("error.codeMismatch")}</>);
      } else if (e.code === "ExpiredCodeException") {
        return toaster.negative(
          <>
            {t("error.expiredCode")}{" "}
            <a href="/#" onClick={resendCode} alt="re-send code">
              {t("confirmCode.resendClick")}
            </a>
          </>
        );
      } else if (e.code === "TooManyFailedAttemptsException") {
        return toaster.negative(<>{t("error.tooManyFailedAttempts")}</>);
      } else if (e.code === "UserNotFoundException") {
        return toaster.negative(<>{t("error.userNotFound")}</>);
      } else if (e.code === "NotAuthorizedException") {
        return toaster.negative(<>{t("error.notAuthorized")}</>);
      } else if (e.code === "PasswordResetRequiredException") {
        return toaster.negative(<>{t("error.passwordResetRequired")}</>);
      } else {
        return toaster.negative(<>{e.message}</>);
      }
    }
  };

  return (
    <div className={confirmationContainer}>
      <p>
        <Trans i18nKey="confirmCode.description" t={t} />
      </p>
      <form onSubmit={handleRegisterConfirmation}>
        <div
          className={inputContainer}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PinCode
            values={confirmationCodeValues}
            onChange={({ values }) => setConfirmationCodeValues(values)}
            autoFocus={true}
            size={SIZE.compact}
          />
        </div>
        <Notification
          kind={NotiKIND.info}
          closeable
          overrides={{
            Body: {
              style: {
                width: "auto",
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
                textAlign: "center",
              },
            },
          }}
        >
          {t("confirmCode.notification")}
        </Notification>
        <div className={inputContainer}>
          <Button
            shape={SHAPE.pill}
            type="submit"
            isLoading={isLoading}
            overrides={{
              BaseButton: {
                style: {
                  width: "100%",
                  backgroundColor: "#00b66c",
                  boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px",
                },
              },
            }}
          >
            {t("confirmCode.button")}
          </Button>
        </div>
      </form>
      <p style={{ fontSize: "0.8rem", textAlign: "center" }}>
        {t("confirmCode.resendDescription")}{" "}
        <a href="/#" onClick={resendCode} alt="re-send code">
          {t("confirmCode.resendClick")}
        </a>
      </p>
      <ToasterContainer
        overrides={{
          ToastBody: {
            style: {
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
              borderBottomLeftRadius: "10px",
            },
          },
        }}
      />
    </div>
  );
};

export default ConfirmEmailCode;
