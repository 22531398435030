import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useStyletron } from "baseui";
import { Input } from "baseui/input";
import { Breadcrumbs } from "baseui/breadcrumbs";
import { toaster, ToasterContainer } from "baseui/toast";
import { Button, SHAPE } from "baseui/button";
import { ListItem, ListItemLabel, ARTWORK_SIZES } from "baseui/list";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { Check } from "baseui/icon";
// import { Notification, KIND as NotiKIND } from 'baseui/notification'
import Emoji from "./Emoji";
import LangSwitch from "./LangSwitch";
import { Auth } from "@aws-amplify/auth";
import ReactGA from "react-ga";
import { Trans, useTranslation } from "react-i18next";
import logo from "../img/logo-walink.svg";
import ConfirmEmailCode from "./ConfirmEmailCode";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [newUser, setNewUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [lengthError, setLengthError] = useState(false);
  const [numbersError, setNumbersError] = useState(false);
  const [lettersError, setLettersError] = useState(false);
  const [upperCaseError, setUpperCaseError] = useState(false);
  const [lowerCaseError, setLowerCaseError] = useState(false);
  const [specialCharacterError, setSpecialCharacterError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [acceptTermsError, setAcceptTermsError] = useState(false);
  const [confirmationPasswordError, setConfirmationPasswordError] =
    useState(false);
  const [css] = useStyletron();
  const { t } = useTranslation("outside");

  const inputContainer = css({
    margin: "1rem 0",
  });

  const pageContainer = css({
    display: "flex",
    flexWrap: "wrap",
    minHeight: "100vh",
  });

  const leftSideContainer = css({
    backgroundColor: "#green",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  });
  const rightSideContainer = css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "-10px 0px 10px 1px rgba(0, 0, 0, 0.2)",
    flex: 2,
  });

  const formContainer = css({
    margin: "1rem 0",
  });

  const registerContainer = css({
    margin: "1.5rem 0rem",
    padding: "0 1rem",
    flex: 1,
    maxWidth: "400px",
  });

  const walinkLogo = css({
    height: "50px",
  });

  const hintText = css({
    fontSize: "0.8rem",
  });

  const cardHeader = css({
    display: "flex",
    alignItems: "center",
  });

  const cardTitle = css({
    marginLeft: "10px",
    borderLeft: "1px solid",
    paddingLeft: "10px",
  });

  const leftTitle = css({
    textAlign: "right",
    color: "#fff",
    padding: "2rem",
  });

  const leftText = css({
    fontSize: "0.9rem",
    margin: "5px",
  });

  useEffect(() => {
    setPasswordError(false);
    setConfirmationPasswordError(false);
    //check if is length 8 or more
    if (password.length < 8) {
      setLengthError(true);
    } else {
      setLengthError(false);
    }

    //check if contains at least one number
    if (/\d/.test(password)) {
      setNumbersError(false);
    } else {
      setNumbersError(true);
    }

    //check if contains at least one alphabetic character
    if (/[a-zA-Z]+/.test(password)) {
      setLettersError(false);
    } else {
      setLettersError(true);
    }

    //check if contains lower case
    if (/[a-z]/.test(password)) {
      setLowerCaseError(false);
    } else {
      setLowerCaseError(true);
    }

    //check if contains upper case
    if (/[A-Z]/.test(password)) {
      setUpperCaseError(false);
    } else {
      setUpperCaseError(true);
    }

    if (/[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/.test(password)) {
      setSpecialCharacterError(false);
    } else {
      setSpecialCharacterError(true);
    }
  }, [password]);

  useEffect(() => {
    if (email) {
      setEmailError(false);
    }
  }, [email]);

  useEffect(() => {
    ReactGA.pageview("/register");
  }, []);

  const handleRegister = async (e) => {
    e.preventDefault();
    //check if email is valid
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      return toaster.negative(<>{t("error.email")}</>);
    }
    //check if password matches with the confirmation password
    if (password !== confirmPassword) {
      setPasswordError(true);
      setConfirmationPasswordError(true);
      return toaster.negative(<>{t("error.passwordMismatch")}</>);
    }
    //check that password does not have any incorrect validation
    if (
      lengthError ||
      numbersError ||
      lettersError ||
      upperCaseError ||
      lowerCaseError ||
      specialCharacterError
    ) {
      setPasswordError(true);
      return toaster.negative(<>{t("error.passwordNotValid")}</>);
    }
    //check if the user accept terms and conditions
    if (!acceptTerms) {
      setAcceptTermsError(true);
      return toaster.negative(<>{t("error.mustAcceptTerms")}</>);
    }
    setIsLoading(true);

    try {
      const newUser = await Auth.signUp({
        username: email.trim().toLowerCase(),
        password: password.trim(),
      });
      setIsLoading(false);
      setNewUser(newUser);
    } catch (e) {
      setIsLoading(false);
      if (e.code === "UsernameExistsException") {
        return toaster.negative(<>{t("error.usernameExists")}</>);
      } else if (e.code === "InvalidPasswordException") {
        return toaster.negative(<>{t("error.invalidPassword")}</>);
      } else if (e.code === "CodeDeliveryFailureException") {
        return toaster.negative(<>{t("error.codeDelivery")}</>);
      } else {
        return toaster.negative(<>{e.message}</>);
      }
    }
  };

  return (
    <div className={pageContainer}>
      <div className={leftSideContainer + " green-gradient"}>
        <div className={leftTitle}>
          <h1 style={{ fontSize: "2.3rem" }}>{t("register.titleOne")}</h1>
          <p
            style={{
              margin: "5px",
              fontSize: "0.9rem",
              paddingBottom: "10px",
            }}
          >
            <Trans i18nKey="register.titleTwo" t={t} />
          </p>
          <p className={leftText}>
            <strong>
              {t("register.featureOne")} <Emoji symbol={"👍"} />
            </strong>
          </p>
          <p className={leftText}>
            <strong>
              {t("register.featureTwo")} <Emoji symbol={"👍"} />
            </strong>
          </p>
          <p className={leftText}>
            <strong>
              {t("register.featureThree")} <Emoji symbol={"👍"} />
            </strong>
          </p>
          <p className={leftText}>
            <strong>
              {t("register.featureFour")} <Emoji symbol={"👍"} />
            </strong>
          </p>
          <p className={leftText}>
            <strong>
              {t("register.featureFive")} <Emoji symbol={"👍"} />
            </strong>
          </p>
          <p className={leftText}>
            <strong>
              {t("register.featureSeven")} <Emoji symbol={"👍"} />
            </strong>
          </p>
          <p className={leftText}>
            <strong>
              {t("register.featureEight")} <Emoji symbol={"👍"} />
            </strong>
          </p>
          <p className={leftText}>{t("register.featureSix")}</p>
        </div>
      </div>
      <div className={rightSideContainer}>
        <div className={registerContainer}>
          <div className={cardHeader}>
            <img src={logo} alt="walink logo" className={walinkLogo} />
            <h2 className={cardTitle}>{t("register.formTitle")}</h2>
          </div>
          {newUser ? (
            <ConfirmEmailCode
              email={email.trim().toLowerCase()}
              password={password.trim()}
            />
          ) : (
            <div className={formContainer}>
              <Breadcrumbs>
                <small>
                  <strong>1. {t("register.stepOne")}</strong>
                </small>
                <small>2. {t("register.stepTwo")}</small>
                <small>3. {t("register.stepThree")}</small>
              </Breadcrumbs>
              {/* <p className={hintText}>{t("register.description")}</p> */}
              {/* <Notification
                kind={NotiKIND.warning}
                overrides={{
                  Body: {
                    style: {
                      width: 'auto',
                      borderTopRightRadius: '10px',
                      borderTopLeftRadius: '10px',
                      borderBottomLeftRadius: '10px',
                      borderBottomRightRadius: '10px',
                      textAlign: 'center',
                    },
                  },
                }}
              >
                {t('error.loginError')}
              </Notification> */}
              <form onSubmit={handleRegister}>
                <div className={inputContainer}>
                  <Input
                    overrides={{
                      Root: {
                        style: {
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        },
                      },
                      InputContainer: {
                        style: {
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        },
                      },
                    }}
                    type="email"
                    placeholder={t("register.emailPlaceholder")}
                    error={emailError}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className={inputContainer}>
                  <Input
                    overrides={{
                      Root: {
                        style: {
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        },
                      },
                      InputContainer: {
                        style: {
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        },
                      },
                    }}
                    type="password"
                    placeholder={t("register.passwordPlaceholder")}
                    error={passwordError}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className={inputContainer}>
                  <Input
                    overrides={{
                      Root: {
                        style: {
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        },
                      },
                      InputContainer: {
                        style: {
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        },
                      },
                    }}
                    type="password"
                    placeholder={t("register.confirmPasswordPlaceholder")}
                    error={confirmationPasswordError}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                {password.length > 0 && (
                  <>
                    <ul
                      className={css({
                        paddingLeft: 0,
                        paddingRight: 0,
                      })}
                    >
                      <ListItem
                        sublist
                        artworkSize={ARTWORK_SIZES.SMALL}
                        artwork={() => GetEmoji(!lengthError)}
                      >
                        <ListItemLabel>
                          {t("passwordHint.length")}
                        </ListItemLabel>
                      </ListItem>
                      <ListItem
                        sublist
                        artworkSize={ARTWORK_SIZES.SMALL}
                        artwork={() =>
                          GetEmoji(!(numbersError || lettersError))
                        }
                      >
                        <ListItemLabel>
                          {t("passwordHint.numbersAndLetters")}
                        </ListItemLabel>
                      </ListItem>
                      <ListItem
                        sublist
                        artworkSize={ARTWORK_SIZES.SMALL}
                        artwork={() =>
                          GetEmoji(!(upperCaseError || lowerCaseError))
                        }
                      >
                        <ListItemLabel>
                          {t("passwordHint.lowerAndUpper")}
                        </ListItemLabel>
                      </ListItem>
                      <ListItem
                        sublist
                        artworkSize={ARTWORK_SIZES.SMALL}
                        artwork={() => GetEmoji(!specialCharacterError)}
                      >
                        <ListItemLabel>
                          {t("passwordHint.specialCharacter")}:
                          <br />
                          <small style={{ fontSize: "12px" }}>
                            {`^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < ' : ; | _ ~ \``}
                          </small>
                        </ListItemLabel>
                      </ListItem>
                    </ul>
                  </>
                )}
                <div className={inputContainer}>
                  <Checkbox
                    checked={acceptTerms}
                    onChange={(e) => {
                      setAcceptTermsError(false);
                      setAcceptTerms(e.target.checked);
                    }}
                    error={acceptTermsError}
                    labelPlacement={LABEL_PLACEMENT.right}
                    overrides={{
                      Checkmark: {
                        style: {
                          borderTopLeftRadius: "5px",
                          borderTopRightRadius: "5px",
                          borderBottomRightRadius: "5px",
                          borderBottomLeftRadius: "5px",
                        },
                      },
                    }}
                  >
                    {t("register.acceptTerms")}{" "}
                    <a
                      href={t("legal.termsUrl")}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("register.termsAndConditions")}{" "}
                    </a>
                    {t("register.andPrivacy")}{" "}
                    <a
                      href={t("legal.policyUrl")}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("register.privacyPolicy")}
                    </a>
                  </Checkbox>
                </div>
                <Button
                  overrides={{
                    BaseButton: {
                      style: {
                        width: "100%",
                        backgroundColor: "#00b66c",
                        boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px",
                      },
                    },
                  }}
                  shape={SHAPE.pill}
                  type="submit"
                  isLoading={isLoading}
                >
                  {t("register.registerButton")}
                </Button>
                <div>
                  <p className={hintText} style={{ textAlign: "center" }}>
                    {t("register.alreadyRegister")}{" "}
                    <strong>
                      <Link to="/">{t("register.login")}</Link>
                    </strong>
                  </p>
                </div>
              </form>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "0.5rem",
                }}
              >
                <LangSwitch />
              </div>
            </div>
          )}

          <ToasterContainer
            overrides={{
              ToastBody: {
                style: {
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  borderBottomRightRadius: "10px",
                  borderBottomLeftRadius: "10px",
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

function GetEmoji(showEmoji) {
  return <>{showEmoji ? <Emoji symbol={"👌"} /> : <Check />}</>;
}
export default Register;
