export default {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://z9yq761cp7.execute-api.us-east-1.amazonaws.com/prod",
    MULTIAGENT_URL:
      "https://896rdxaipb.execute-api.us-east-1.amazonaws.com/prod",

    FORM_URL: "https://0to6m2pt6f.execute-api.us-east-1.amazonaws.com/prod",
    V2_URL: "https://ib1hw5acpg.execute-api.us-east-1.amazonaws.com/Prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_E2isN1gTH",
    APP_CLIENT_ID: "kfp5hb5hmfm3rfcbl7523j8rn",
    IDENTITY_POOL_ID: "us-east-1:40bbee50-b511-4237-bc6f-b05b9b0a9385",
  },
  s3: {
    REGION: "us-east-1",
    BUCKET: "walink-private",
    IDENTITY_POOL_ID: "us-east-1:40bbee50-b511-4237-bc6f-b05b9b0a9385",
  },
};
