import React, { useState, useEffect, Suspense, lazy } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import Login from "./components/Login";
// import Main from './components/Main'
import Register from "./components/Register";
import ResetPassword from "./components/ResetPassword";
import FullLoading from "./components/FullLoading";
import { toaster, ToasterContainer } from "baseui/toast";
import { LocaleProvider } from "baseui";
import AppContext from "./context/AppContext";
import { Auth } from "@aws-amplify/auth";
import { useTranslation } from "react-i18next";
import "./App.css";
const Main = lazy(() => import("./components/Main"));

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const [profileError, setProfileError] = useState(false);
  const { i18n } = useTranslation("inside");

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      setIsAuth(true);
    } catch (e) {
      if (e !== "No current user") {
        console.log(e);
        toaster.negative(<>Could not login. Reload the page</>);
      }
    }
    setAuthLoading(false);
  }

  return (
    <Router>
      <LocaleProvider locale={i18n.languages[0] === "es" ? localeEs : localeEn}>
        <AppContext.Provider
          value={{
            isAuth,
            setIsAuth,
            profile,
            setProfile,
            profileError,
            setProfileError,
          }}
        >
          {authLoading ? (
            <FullLoading />
          ) : (
            <>
              {!isAuth ? (
                <Switch>
                  <Route exact path="/register">
                    <Register />
                  </Route>
                  <Route exact path="/reset-password">
                    <ResetPassword />
                  </Route>
                  <Route path="/">
                    <Login />
                  </Route>
                </Switch>
              ) : (
                <Suspense fallback={<FullLoading />}>
                  <Main />
                </Suspense>
              )}
            </>
          )}
          <ToasterContainer
            overrides={{
              ToastBody: {
                style: {
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  borderBottomRightRadius: "10px",
                  borderBottomLeftRadius: "10px",
                },
              },
            }}
          />
        </AppContext.Provider>
      </LocaleProvider>
    </Router>
  );
}

const localeEs = {
  select: {
    create: "Crear nuevo link 👉",
  },
};
const localeEn = {
  select: {
    create: "Create new link 👉",
  },
};

export default App;
