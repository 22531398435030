import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import ReactGA from "react-ga";
import App from "./App";
import { Auth } from "@aws-amplify/auth";
import API from "@aws-amplify/api";
import { Storage } from "@aws-amplify/storage";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";
import { Client as Styletron } from "styletron-engine-atomic";
import * as serviceWorker from "./serviceWorker";
import config from "./config";
import "./i18n";

ReactGA.initialize("UA-177946790-1");

const engine = new Styletron();

Auth.configure({
  mandatorySignIn: true,
  region: config.cognito.REGION,
  userPoolId: config.cognito.USER_POOL_ID,
  identityPoolId: config.cognito.IDENTITY_POOL_ID,
  userPoolWebClientId: config.cognito.APP_CLIENT_ID,
});

API.configure({
  endpoints: [
    {
      name: "links",
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION,
    },
    {
      name: "multiagent",
      endpoint: config.apiGateway.MULTIAGENT_URL,
      region: config.apiGateway.REGION,
    },
    {
      name: "form",
      endpoint: config.apiGateway.FORM_URL,
      region: config.apiGateway.REGION,
    },
    {
      name: "v2",
      endpoint: config.apiGateway.V2_URL,
      region: config.apiGateway.REGION,
    },
  ],
});

Storage.configure({
  region: config.s3.REGION,
  bucket: config.s3.BUCKET,
  identityPoolId: config.cognito.IDENTITY_POOL_ID,
});

ReactDOM.render(
  <StyletronProvider value={engine}>
    <BaseProvider theme={LightTheme}>
      {/* <Suspense fallback={<FullLoading />}> */}
      <App />
      {/* </Suspense> */}
    </BaseProvider>
  </StyletronProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
