import React from 'react'
import { MdTranslate } from 'react-icons/md'
import { Button, SHAPE, SIZE, KIND } from 'baseui/button'
import { StatefulMenu } from 'baseui/menu'
import { StatefulPopover } from 'baseui/popover'
import { useTranslation } from 'react-i18next'

const LangSwitch = () => {
  const { i18n } = useTranslation('inside')
  return (
    <StatefulPopover
      content={() => (
        <StatefulMenu
          onItemSelect={({ item }) => i18n.changeLanguage(item.lang)}
          items={[
            { label: 'English', lang: 'en' },
            { label: 'Español', lang: 'es' },
          ]}
        />
      )}
      showArrow
    >
      <Button
        kind={KIND.minimal}
        size={SIZE.mini}
        shape={SHAPE.pill}
        overrides={{
          BaseButton: {
            style: {
              marginRight: '5px',
              marginLeft: '5px',
            },
          },
        }}
      >
        <MdTranslate size="14px" style={{ marginRight: 4 }} />
        lang | {i18n.languages[0] === 'es' ? 'es' : 'en'}
      </Button>
    </StatefulPopover>
  )
}

export default LangSwitch
