import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useStyletron } from "baseui";
import { Button, SHAPE } from "baseui/button";
import { Input } from "baseui/input";
import { toaster, ToasterContainer } from "baseui/toast";
import { ListItem, ListItemLabel, ARTWORK_SIZES } from "baseui/list";
import { Notification, KIND as NotiKIND } from "baseui/notification";
import { Check } from "baseui/icon";
import Emoji from "./Emoji";
import { Auth } from "@aws-amplify/auth";
import { useTranslation, Trans } from "react-i18next";
import LangSwitch from "./LangSwitch";
import logo from "../img/logo-walink.svg";

const ResetPassword = () => {
  const [css] = useStyletron();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [code, setCode] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmationPasswordError, setConfirmationPasswordError] =
    useState(false);
  const [codeError, setCodeError] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [success, setSuccess] = useState(false);
  const [sendCodeLoading, setSendCodeLoading] = useState(false);
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);
  const [lengthError, setLengthError] = useState(false);
  const [numbersError, setNumbersError] = useState(false);
  const [lettersError, setLettersError] = useState(false);
  const [upperCaseError, setUpperCaseError] = useState(false);
  const [lowerCaseError, setLowerCaseError] = useState(false);
  const [specialCharacterError, setSpecialCharacterError] = useState(false);
  const history = useHistory();
  const { t } = useTranslation("outside");

  const card = css({
    margin: "1rem",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "100%",
    maxWidth: "350px",
  });

  const inputContainer = css({
    margin: "0.8rem 0",
  });

  const walinkLogo = css({
    height: "50px",
  });

  const container = css({
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  });

  const cardHeader = css({
    display: "flex",
    alignItems: "center",
  });

  const cardTitle = css({
    marginLeft: "10px",
    borderLeft: "1px solid",
    paddingLeft: "10px",
  });

  const hintText = css({
    fontSize: "0.8rem",
  });

  useEffect(() => {
    setPasswordError(false);
    setConfirmationPasswordError(false);
    //check if is length 8 or more
    if (password.length < 8) {
      setLengthError(true);
    } else {
      setLengthError(false);
    }

    //check if contains at least one number
    if (/\d/.test(password)) {
      setNumbersError(false);
    } else {
      setNumbersError(true);
    }

    //check if contains at least one alphabetic character
    if (/[a-zA-Z]+/.test(password)) {
      setLettersError(false);
    } else {
      setLettersError(true);
    }

    //check if contains lower case
    if (/[a-z]/.test(password)) {
      setLowerCaseError(false);
    } else {
      setLowerCaseError(true);
    }

    //check if contains upper case
    if (/[A-Z]/.test(password)) {
      setUpperCaseError(false);
    } else {
      setUpperCaseError(true);
    }

    if (/[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/.test(password)) {
      setSpecialCharacterError(false);
    } else {
      setSpecialCharacterError(true);
    }
  }, [password]);

  useEffect(() => {
    if (email) {
      setEmailError(false);
    }
  }, [email]);

  useEffect(() => {
    if (code) {
      setCodeError(false);
    }
  }, [code]);

  const sendResetPasswordCode = async (e) => {
    e.preventDefault();
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      return toaster.negative(<>{t("error.email")}</>);
    }
    setSendCodeLoading(true);
    try {
      await Auth.forgotPassword(email.trim());
      setCodeSent(true);
    } catch (e) {
      setSendCodeLoading(false);
      if (e.code === "CodeDeliveryFailureException") {
        return toaster.negative(<>{t("error.codeDelivery")}</>);
      } else if (e.code === "InvalidParameterException") {
        return toaster.negative(<>{t("error.invalidParameter")}</>);
      } else if (e.code === "LimitExceededException") {
        return toaster.negative(<>{t("error.limitExceeded")}</>);
      } else if (e.code === "NotAuthorizedException") {
        return toaster.negative(<>{t("error.notAuthorizedReset")}</>);
      } else if (e.code === "ResourceNotFoundException") {
        return toaster.negative(<>{t("error.resourceNotFound")}</>);
      } else if (e.code === "TooManyRequestsException") {
        return toaster.negative(<>{t("error.limitExceeded")}</>);
      } else if (e.code === "UserNotConfirmedException") {
        return toaster.negative(<>{t("error.userNotConfirmedReset")}</>);
      } else if (e.code === "UserNotFoundException") {
        return toaster.negative(<>{t("error.userNotFound")}</>);
      } else {
        return toaster.negative(<>{e.message}</>);
      }
    }
  };

  const changePassword = async (e) => {
    e.preventDefault();
    //check if email is valid
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      return toaster.negative(<>{t("error.email")}</>);
    }
    if (!code) {
      setCodeError(true);
      return toaster.negative(<>{t("error.codeEmpty")}</>);
    }
    //check if password matches with the confirmation password
    if (password !== confirmPassword) {
      setPasswordError(true);
      setConfirmationPasswordError(true);
      return toaster.negative(<>{t("error.passwordMismatch")}</>);
    }
    //check that password does not have any incorrect validation
    if (
      lengthError ||
      numbersError ||
      lettersError ||
      upperCaseError ||
      lowerCaseError ||
      specialCharacterError
    ) {
      setPasswordError(true);
      return toaster.negative(<>{t("error.passwordNotValid")}</>);
    }
    setChangePasswordLoading(true);
    try {
      await Auth.forgotPasswordSubmit(
        email.trim(),
        code.trim(),
        password.trim()
      );
      setChangePasswordLoading(false);
      setSuccess(true);
      toaster.positive(<>{t("resetPassword.successMessage")}</>, {
        autoHideDuration: 5000,
        onClose: () => history.push("/"),
      });
    } catch (e) {
      setChangePasswordLoading(false);
      if (e.code === "CodeMismatchException") {
        setCodeError(true);
        return toaster.negative(<>{t("error.codeMismatch")}</>);
      } else if (e.code === "ExpiredCodeException") {
        setCodeError(true);
        return toaster.negative(<>{t("error.codeExpired")}</>);
      } else if (e.code === "UserNotConfirmedException") {
        return toaster.negative(<>{t("error.userNotConfirmedReset")}</>);
      } else if (e.code === "UserNotFoundException") {
        return toaster.negative(<>{t("error.userNotFound")}</>);
      } else if (e.code === "InvalidParameterException") {
        return toaster.negative(<>{t("error.invalidParameter")}</>);
      } else if (e.code === "InvalidPasswordException") {
        return toaster.negative(<>{t("error.passowrdNotValid")}</>);
      } else if (e.code === "LimitExceededException") {
        return toaster.negative(<>{t("error.limitExceeded")}</>);
      } else if (e.code === "TooManyFailedAttemptsException") {
        return toaster.negative(<>{t("error.limitExceeded")}</>);
      } else if (e.code === "TooManyRequestsException") {
        return toaster.negative(<>{t("error.limitExceeded")}</>);
      } else if (e.code === "NotAuthorizedException") {
        return toaster.negative(<>{t("error.notAuthorizedReset")}</>);
      } else if (e.code === "ResourceNotFoundException") {
        return toaster.negative(<>{t("error.resourceNotFound")}</>);
      } else {
        return toaster.negative(<>{e.message}</>);
      }
    }
  };

  return (
    <div>
      <div className={container}>
        <div className={card}>
          <div className={cardHeader}>
            <img src={logo} alt="walink logo" className={walinkLogo} />
            <h3 className={cardTitle}>{t("resetPassword.title")}</h3>
          </div>
          {codeSent ? (
            <>
              <div className={inputContainer}>
                <p className={hintText}>
                  <Trans
                    i18nKey="resetPassword.codeSentDescription"
                    t={t}
                    values={{ email }}
                  />
                </p>
              </div>

              <Notification
                kind={NotiKIND.info}
                closeable
                overrides={{
                  Body: {
                    style: {
                      width: "auto",
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                      borderBottomLeftRadius: "10px",
                      borderBottomRightRadius: "10px",
                      textAlign: "center",
                    },
                  },
                }}
              >
                {t("resetPassword.notification")}
              </Notification>
              <form onSubmit={changePassword}>
                <div className={inputContainer}>
                  <Input
                    overrides={{
                      Root: {
                        style: {
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        },
                      },
                      InputContainer: {
                        style: {
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        },
                      },
                    }}
                    type="text"
                    placeholder={t("resetPassword.codePlaceholder")}
                    error={codeError}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
                <div className={inputContainer}>
                  <Input
                    overrides={{
                      Root: {
                        style: {
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        },
                      },
                      InputContainer: {
                        style: {
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        },
                      },
                    }}
                    type="password"
                    placeholder={t("resetPassword.passwordPlaceholder")}
                    error={passwordError}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className={inputContainer}>
                  <Input
                    overrides={{
                      Root: {
                        style: {
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        },
                      },
                      InputContainer: {
                        style: {
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        },
                      },
                    }}
                    type="password"
                    placeholder={t("resetPassword.confirmPasswordPlaceholder")}
                    error={confirmationPasswordError}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                {password.length > 0 && (
                  <ul
                    className={css({
                      paddingLeft: 0,
                      paddingRight: 0,
                    })}
                  >
                    <ListItem
                      sublist
                      artworkSize={ARTWORK_SIZES.SMALL}
                      artwork={() => GetEmoji(!lengthError)}
                    >
                      <ListItemLabel>{t("passwordHint.length")}</ListItemLabel>
                    </ListItem>
                    <ListItem
                      sublist
                      artworkSize={ARTWORK_SIZES.SMALL}
                      artwork={() => GetEmoji(!(numbersError || lettersError))}
                    >
                      <ListItemLabel>
                        {t("passwordHint.numbersAndLetters")}
                      </ListItemLabel>
                    </ListItem>
                    <ListItem
                      sublist
                      artworkSize={ARTWORK_SIZES.SMALL}
                      artwork={() =>
                        GetEmoji(!(upperCaseError || lowerCaseError))
                      }
                    >
                      <ListItemLabel>
                        {t("passwordHint.lowerAndUpper")}
                      </ListItemLabel>
                    </ListItem>
                    <ListItem
                      sublist
                      artworkSize={ARTWORK_SIZES.SMALL}
                      artwork={() => GetEmoji(!specialCharacterError)}
                    >
                      <ListItemLabel>
                        {t("passwordHint.specialCharacter")}
                      </ListItemLabel>
                    </ListItem>
                  </ul>
                )}
                <Button
                  overrides={{
                    BaseButton: {
                      style: {
                        width: "100%",
                        backgroundColor: "#00b66c",
                        boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px",
                      },
                    },
                  }}
                  shape={SHAPE.pill}
                  type="submit"
                  isLoading={changePasswordLoading}
                  disabled={success}
                >
                  {t("resetPassword.changePassword")}
                </Button>
              </form>
            </>
          ) : (
            <>
              <div className={inputContainer}>
                <p className={hintText}>
                  {t("resetPassword.sendCodeDescription")}
                </p>
              </div>
              <div className={inputContainer}>
                <Input
                  overrides={{
                    Root: {
                      style: {
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",
                        borderBottomLeftRadius: "10px",
                      },
                    },
                    InputContainer: {
                      style: {
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",
                        borderBottomLeftRadius: "10px",
                      },
                    },
                  }}
                  type="email"
                  placeholder={t("resetPassword.emailPlaceholder")}
                  error={emailError}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className={inputContainer}>
                <Button
                  overrides={{
                    BaseButton: {
                      style: {
                        width: "100%",
                        backgroundColor: "#00b66c",
                        boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px",
                      },
                    },
                  }}
                  shape={SHAPE.pill}
                  isLoading={sendCodeLoading}
                  onClick={sendResetPasswordCode}
                >
                  {t("resetPassword.sendCode")}
                </Button>
              </div>
            </>
          )}
          <p className={hintText} style={{ textAlign: "center" }}>
            <Link to="/">{t("resetPassword.backToLogin")}</Link>
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LangSwitch />
          </div>
        </div>
      </div>
      <ToasterContainer
        overrides={{
          ToastBody: {
            style: {
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
              borderBottomLeftRadius: "10px",
            },
          },
        }}
      />
    </div>
  );
};

function GetEmoji(showEmoji) {
  return <>{showEmoji ? <Emoji symbol={"👌"} /> : <Check />}</>;
}

export default ResetPassword;
