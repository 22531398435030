import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useStyletron } from "baseui";
import { Input } from "baseui/input";
import { Button, SHAPE } from "baseui/button";
import { toaster, ToasterContainer } from "baseui/toast";
// import { Notification, KIND as NotiKIND } from "baseui/notification";
import ConfirmEmailCode from "./ConfirmEmailCode";
import logo from "../img/logo-walink.svg";
import { Auth } from "@aws-amplify/auth";
import AppContext from "../context/AppContext";
import LangSwitch from "./LangSwitch";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showConfirmationCode, setShowConfirmationCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [css] = useStyletron();
  const ctx = useContext(AppContext);
  const { t } = useTranslation("outside");

  const card = css({
    margin: "1rem",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "100%",
    maxWidth: "350px",
  });

  const inputContainer = css({
    margin: "0.8rem 0",
  });

  const walinkLogo = css({
    height: "50px",
  });

  const container = css({
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  });

  const cardHeader = css({
    display: "flex",
    alignItems: "center",
  });

  const cardTitle = css({
    marginLeft: "10px",
    borderLeft: "1px solid",
    paddingLeft: "10px",
  });

  const hintText = css({
    fontSize: "0.8rem",
  });

  useEffect(() => {
    ReactGA.pageview("/login");
  }, []);

  useEffect(() => {
    setPasswordError(false);
  }, [password]);

  useEffect(() => {
    setEmailError(false);
  }, [email]);

  const resendCode = async () => {
    try {
      await Auth.resendSignUp(email.trim());
    } catch (e) {
      return toaster.negative(<>{e.message}</>);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    //check if email is valid
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      return toaster.negative(<>{t("error.email")}</>);
    }
    //check if password is set
    if (!password) {
      setPasswordError(true);
      return toaster.negative(<>{t("error.password")}</>);
    }
    setIsLoading(true);
    try {
      await Auth.signIn(email.trim(), password.trim());
      ctx.setIsAuth(true);
    } catch (e) {
      setIsLoading(false);

      if (e.code === "UserNotFoundException") {
        return toaster.negative(<>{t("error.userNotFound")}</>);
      } else if (e.code === "NotAuthorizedException") {
        return toaster.negative(<>{t("error.notAuthorized")}</>);
      } else if (e.code === "UserNotConfirmedException") {
        resendCode();
        setShowConfirmationCode(true);
        return toaster.warning(<>{t("error.userNotConfirmed")}</>);
      } else if (e.code === "PasswordResetRequiredException") {
        return toaster.negative(<>{t("error.passwordResetRequired")}</>);
      } else {
        return toaster.negative(<>{e.message}</>);
      }
    }
  };

  return (
    <>
      <div className={container}>
        <div className={card}>
          <div className={cardHeader}>
            <img src={logo} alt="walink logo" className={walinkLogo} />
            <p className={cardTitle}>Premium</p>
          </div>
          {showConfirmationCode ? (
            <ConfirmEmailCode email={email.trim()} password={password.trim()} />
          ) : (
            <>
              <div className={inputContainer}>
                <p className={hintText}>
                  {t("login.noAccount")}{" "}
                  <strong>
                    <Link to="/register">{t("login.register")}</Link>
                  </strong>
                </p>
              </div>

              {/* <Notification
                kind={NotiKIND.warning}
                overrides={{
                  Body: {
                    style: {
                      width: "auto",
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                      borderBottomLeftRadius: "10px",
                      borderBottomRightRadius: "10px",
                      textAlign: "center",
                    },
                  },
                }}
              >
                {t("error.loginError")}
              </Notification> */}
              <form onSubmit={handleLogin}>
                <div className={inputContainer}>
                  <Input
                    overrides={{
                      Root: {
                        style: {
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        },
                      },
                      InputContainer: {
                        style: {
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        },
                      },
                    }}
                    type="email"
                    placeholder={t("login.emailPlaceholder")}
                    error={emailError}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className={inputContainer}>
                  <Input
                    overrides={{
                      Root: {
                        style: {
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        },
                      },
                      InputContainer: {
                        style: {
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        },
                      },
                    }}
                    type="password"
                    placeholder={t("login.passwordPlaceholder")}
                    error={passwordError}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className={inputContainer}>
                  <Button
                    overrides={{
                      BaseButton: {
                        style: {
                          width: "100%",
                          backgroundColor: "#00b66c",
                          boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px",
                        },
                      },
                    }}
                    shape={SHAPE.pill}
                    isLoading={isLoading}
                    type="submit"
                  >
                    {t("login.button")}
                  </Button>
                </div>
                <p className={hintText} style={{ textAlign: "center" }}>
                  <Link to="/reset-password">{t("login.resetPassword")}</Link>
                </p>
              </form>
            </>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "0.5rem",
            }}
          >
            <LangSwitch />
          </div>
        </div>
        <ToasterContainer
          overrides={{
            ToastBody: {
              style: {
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                borderBottomRightRadius: "10px",
                borderBottomLeftRadius: "10px",
              },
            },
          }}
        />
      </div>
    </>
  );
};

export default Login;
