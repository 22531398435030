import React from 'react'
import { StyledSpinnerNext } from 'baseui/spinner'
import { withStyle } from 'baseui'
import { useStyletron } from 'baseui'

const ExtraLargeSpinner = withStyle(StyledSpinnerNext, {
  width: '74px',
  height: '74px',
  borderWidth: '12px',
  borderTopColor: '#00b66c',
})

const FullLoadgin = () => {
  const [css] = useStyletron()

  const fullWindow = css({
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })

  return (
    <div className={fullWindow}>
      <ExtraLargeSpinner />
    </div>
  )
}

export default FullLoadgin
